.fade-in-section {
  opacity: 0;
  transform: translateY(7vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.3s ease-out;
  will-change: opacity, visibility;

  &.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}
