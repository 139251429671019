.c-about {
  background: #2b2b2b;
  color: #d0d0d0;
  padding-top: 5rem;
  padding-bottom: 5rem;
  overflow: hidden;

  p:last-child {
    margin-bottom: 0;
  }

  @include media(768) {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  &__pic {
    display: none;

    @include media(768) {
      display: block;
    }

    &-content {
      display: none;

      @include media(768) {
        display: block;
        width: 114px;
        height: auto;
      }

      @include media(900) {
        position: relative;
        width: 120px;
        height: auto;
        border-radius: 100%;
      }
    }
  }
}
