/* prettier-ignore */
@for $i from 0 through 6 {
  .u-pad--#{$i} {
    padding: #{$i}rem !important;
  }

  .u-pad-x--#{$i} {
    padding-left: #{$i}rem !important;
    padding-right: #{$i}rem !important;
  }

  .u-pad-y--#{$i} {
    padding-top: #{$i}rem !important;
    padding-bottom: #{$i}rem !important;
  }

  .u-pad-t--#{$i} {
    padding-top: #{$i}rem !important;
  }

  .u-pad-b--#{$i} {
    padding-bottom: #{$i}rem !important;
  }

  .u-pad-l--#{$i} {
    padding-left: #{$i}rem !important;
  }

  .u-pad-r--#{$i} {
    padding-right: #{$i}rem !important;
  }

  .u-mar--#{$i} {
    margin: #{$i}rem !important;
  }

  .u-mar-x--#{$i} {
    margin-left: #{$i}rem !important;
    margin-right: #{$i}rem !important;
  }

  .u-mar-y--#{$i} {
    margin-top: #{$i}rem !important;
    margin-bottom: #{$i}rem !important;
  }

  .u-mar-t--#{$i} {
    margin-top: #{$i}rem !important;
  }

  .u-mar-b--#{$i} {
    margin-bottom: #{$i}rem !important;
  }

  .u-mar-l--#{$i} {
    margin-left: #{$i}rem !important;
  }

  .u-mar-r--#{$i} {
    margin-right: #{$i}rem !important;
  }
}
