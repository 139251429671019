.c-tech {
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  &__container {
    background: #f8f8f8;
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include media(1024) {
      padding-top: 9rem;
      padding-bottom: 9rem;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    height: auto;
    flex: 0 1 calc(50% - 2rem);
    max-width: calc(50% - 2rem);
    margin: 2rem 1rem 0;
    padding: 1.5rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);

    @include media(768) {
      flex: 0 1 calc(25% - 2rem);
      max-width: calc(25% -2rem);
      margin: 2rem 1rem 0;
    }

    @include media(1024) {
      flex: 0 1 calc(20% - 3rem);
      max-width: calc(20% - 3rem);
      margin: 3rem 1.5rem 0;
      padding: 3rem 1.5rem;
    }

    &-icon {
      height: 10rem;

      @include media(480) {
        height: 12rem;
      }
    }
  }
}

.my-node {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
}
