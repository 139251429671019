.c-portfolio {
  background: #ebeeee;
  padding-top: $big-gap;
  padding-bottom: $big-gap;

  &__headline {
    display: block;
    text-align: center;
    margin-bottom: $medium-gap;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    transition: all 0.5s;
  }

  &__item {
    width: 100%;
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.5s;

    @include media(480) {
      width: 50%;
    }

    @include media(768) {
      width: 33%;
    }

    &:hover {
      .c-portfolio__item-overlay {
        display: block;
        transition: all 0.5s;
        background: rgba(0, 0, 0, 0.4);
      }
    }

    &-wrapper {
      position: relative;

      a {
        display: flex;
      }
    }

    &-overlay {
      display: none;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      padding: 1.5rem;
    }

    &-title,
    &-text {
      color: #ffffff;
    }
  }
}
