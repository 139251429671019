.c-hero {
  display: flex;
  align-items: center;
  position: relative;
  height: 800px;
  min-height: 500px;
  width: 100%;
  //background: #161415 url(../assets/images/header-background.jpg) no-repeat top center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  &__content {
    display: inline-block;
    margin: 0 auto;
    width: 85%;
    padding-bottom: 3rem;
    text-align: center;
    vertical-align: middle;
  }

  &__text-section {
    width: 100%;
  }

  &__headline {
    font: 90px/1.1em "opensans-bold", sans-serif;
    color: #fff;
    letter-spacing: -2px;
    margin: 0 auto 3rem auto;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
  }

  &__subheadline {
    font: 18px/1.9em "librebaskerville-regular", serif;
    color: #a8a8a8;
    margin: 0 auto;
    width: 70%;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    display: none;

    @include media(480) {
      display: block;
    }

    span,
    a {
      color: #fff;
    }
  }

  &__hr {
    width: 60%;
    margin: 1.8rem auto 2.4rem auto;
    border-color: rgba(213, 213, 213, 0.25);
  }

  .scrolldown {
    display: none;
  }
}

.c-social {
  margin: 1.2rem 0;
  padding: 0;
  font-size: 32px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);

  &__item {
    display: inline-block;
    margin: 0 1.5rem;
    padding: 0;
    font-size: 100%;

    &-link {
      display: inline-block;
      transition: transform 0.3s;
      color: #fff;

      &:hover,
      &:focus {
        color: #fff;
        transform: scale(1.2);
      }
    }
  }
}

.c-hero .scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;
  transition: all 0.3s ease-in-out;
}

@include media(480) {

  /* header styles
   -------------------------------------------------------------------- */
  .c-hero__content {
    padding-top: 24px;
  }

  .c-hero__headline {
    font: 40px/1.1em "opensans-bold", sans-serif;
    margin: 0 auto 3rem auto;
  }

  .c-hero__subheadline {
    font: 14px/1.9em "librebaskerville-regular", sans-serif;
    width: 90%;
  }

  .c-social__item {
    margin: 0 1.5rem;
  }
}

@include media(768) {
  .c-hero__content {
    padding-bottom: 1.2rem;
    padding-top: 0.6rem;
  }

  .c-hero__headline {
    font: 68px/1.1em "opensans-bold", sans-serif;
  }

  .c-hero__subheadline {
    font: 16px/1.9em "librebaskerville-regular", serif;
    width: 85%;
  }

  .c-hero__hr {
    width: 80%;
    margin: 1.8rem auto;
  }

  /* .c-hero social links */
  .c-social {
    margin: 1.8rem 0 2.4rem 0;
    font-size: 36px;
    line-height: 36px;
  }

  .c-social__item {
    margin: 0 1.8rem;
  }

  /* scrolldown link */
  .c-hero .scrolldown {
    display: block;
  }
}

@include media(900) {
  .c-hero__content {
    padding-bottom: 1.2rem;
  }

  .c-hero__headline {
    font: 78px/1.1em "opensans-bold", sans-serif;
    letter-spacing: -1px;
  }

  .c-hero__subheadline {
    font: 17px/1.9em "librebaskerville-regular", serif;
    width: 80%;
  }

  .c-hero__hr {
    width: 65%;
    margin: 1.2rem auto;
  }
}

@include media(1024) {
  .c-hero__headline {
    font: 75px/1.1em "opensans-bold", sans-serif;
    letter-spacing: -1px;
    margin: 0 auto 3rem auto;
  }
}