.c-resume {
  background: #f8f8f8;
  padding-top: 5rem;
  overflow: hidden;

  @include media(768) {
    padding-top: 9rem;
  }

  &__headline {
    font: 18px/24px "opensans-bold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 1.2rem;
    position: relative;

    &:after {
      content: "";
      display: block;
      border-bottom: 3px solid #11abb0;
      position: absolute;
      bottom: 0;
      width: 55px;
    }
  }
}

.c-work {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem;
  border-bottom: 1px solid #e8e8e8;

  @include media(1024) {
    padding-bottom: 9rem;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3rem;

    @include media(1024) {
      flex-direction: row;
      align-items: flex-start;
    }

    &-headline {
      @include media(1024) {
        margin-right: 4rem;
        width: 25rem;
      }
    }
  }

  &__content {
    width: 100%;

    @include media(480) {
      padding: 0 2rem;
    }
  }

  &__info {
    font: 16px/24px "librebaskerville-italic", serif;
    color: #6e7881;
    margin-bottom: 2rem;
    margin-top: 3px;
  }

  &__list {
    list-style: none;
    margin-bottom: 0;

    &-item {
      position: relative;
      margin-left: 1.8rem;

      &:before {
        content: "•";
        position: absolute;
        left: -15px;
        font-size: 2rem;
        color: #11abb0;
      }
    }
  }

  &__date {
    font: 15px/24px "opensans-regular", sans-serif;
    margin-top: 6px;
  }
}
