.c-mobile-nav__icon {
  $copycolor: #f8ffe9;

  height: 4px;
  width: 30px;
  top: 15px;
  background-color: $copycolor;
  border-radius: 20px;
  position: absolute;
  transition-duration: 0.5s;

  &:before {
    left: 0;
    position: absolute;
    top: -10px;
    height: 4px;
    width: 30px;
    background-color: $copycolor;
    content: "";
    border-radius: 20px;
    transition-duration: 0.5s;
  }

  &:after {
    left: 0;
    position: absolute;
    top: 10px;
    height: 4px;
    width: 30px;
    background-color: $copycolor;
    content: "";
    border-radius: 20px;
    transition-duration: 0.5s;
  }
}

.c-mobile-nav {
  position: fixed;
  top: 1rem;
  right: 0rem;
  padding: 0 1rem;
  background: #11abb0;
  width: 50px;
  height: 35px;
  transition-duration: 0.8s;
  display: inline-block;
  z-index: 99;
  //box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  cursor: pointer;

  @include media(768) {
    display: none;
  }

  &__icon {
    transition-duration: 0.5s;
    transition-delay: 0.5s;

    &:after {
      transition: transform 0.5s, top 0.5s 0.5s;
    }

    &:before {
      transition: transform 0.5s, top 0.5s 0.5s;
    }
  }

  &--open {
    box-shadow: none;
    background: transparent;

    .c-mobile-nav__icon {
      transition-duration: 0.1s;
      transition-delay: 0.5s;
      background: transparent;

      &:before {
        transition: top 0.5s, transform 0.5s 0.5s;
        top: 0px;
        transform: rotateZ(-45deg);
      }

      &:after {
        transition: top 0.4s, transform 0.5s 0.5s;
        top: 0px;
        transform: rotateZ(45deg);
      }
    }
  }
}

.backdrop {
  visibility: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s;

  &--active {
    visibility: visible;
  }
}

@keyframes animateNavIn {
  0% {
    top: -110%;
  }

  100% {
    top: 0%;
  }
}

@keyframes animateNavOut {
  0% {
    top: 0%;
  }

  100% {
    top: -110%;
  }
}

.c-nav {
  position: fixed;
  height: 100vh;
  top: -110%;
  right: 0;
  display: flex;
  flex-direction: column;
  background: #11abb0;
  width: 100vw;
  margin-top: 0;
  clip-path: ellipse(200% 95% at 100% 0);
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;

  @include media(768) {
    flex-direction: row;
    justify-content: center;
    height: auto;
    top: 0;
    max-width: none;
    background: inherit;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    min-height: 48px;
    width: 100%;
    text-align: center;
  }

  & li:first-child {
    margin-top: 6rem;

    @include media(768) {
      margin-top: 0;
    }
  }

  &--open {
    animation: animateNavIn 0.8s;
    top: 0%;

    @include media(768) {
      animation: none;
    }
  }

  &--closed {
    animation: animateNavOut 0.8s;

    @include media(768) {
      animation: none;
    }
  }

  &__wrapper {
    font: 11px "opensans-bold", sans-serif;
    letter-spacing: 1.5px;
    width: 100%;
    text-transform: uppercase;
    margin: 0 auto;
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;

    @include media(768) {
      font: 12px "opensans-bold", sans-serif;
      letter-spacing: 2.5px;
    }

    &.opaque {
      background: #333;
    }
  }

  &__item {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    position: relative;
    list-style: none;
    height: 48px;
    display: inline-block;

    &-link {
      display: inline-block;
      line-height: 32px;
      text-decoration: none;
      text-align: left;
      color: #fff;
      transition: color 0.2s ease-in-out;
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
      font-size: 2rem;

      @include media(768) {
        padding: 8px 13px;
        font-size: 1.3rem;

        .current &,
        &:active,
        &:focus {
          color: #f06000;
        }
      }

      &:active {
        background-color: transparent !important;
      }

      &:hover {
        color: #f06000;
      }
    }
  }
}
