.c-testemonials {
  background: #1f1f1f url(../images/testemonials_bg1.png) no-repeat bottom
    center;
  background-size: cover !important;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-attachment: fixed;

  position: relative;
  min-height: 200px;
  width: 100%;
  overflow: hidden;

  @include media(768) {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  &__list {
    padding: 0;
    margin: 1rem 0;
    @include media(480) {
      padding: 0 1.5rem;
    }
  }

  &__headline {
    font: 18px/24px "opensans-bold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;

    &:before {
      font-family: "FontAwesome";
      content: "\f10d";
      padding-right: 10px;
      font-size: 72px;
      line-height: 72px;
      text-align: left;
      float: left;
      color: #fff;
    }
  }

  &__paragraph {
    font-family: "librebaskerville-italic", serif;
    padding: 0;
    font-size: 24px;
    line-height: 48px;
    color: #fff;
  }

  blockquote {
    color: #fff;

    &:before {
      color: #fff;
      opacity: 0.8;
    }
  }
}
