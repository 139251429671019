.c-footer {
  padding-top: 7rem;
  margin-bottom: 5rem;
  color: #303030;
  font-size: 14px;
  text-align: center;
  position: relative;

  @include media(768) {
    margin-bottom: 7rem;
  }
}

.c-copyright {
  margin: 0;
  padding: 0;

  &__item {
    display: inline-block;
    padding: 0;
    line-height: 24px;
    margin-right: 1.2rem;
    color: #ffffff;

    @include media(768) {
      margin: 0;
    }

    &:before {
      content: none;

      @include media(768) {
        content: "\2022";
        padding-left: 1rem;
        padding-right: 1rem;
        color: #ffffff;
      }
    }

    &:first-child:before {
      display: none;
    }
  }
}

.c-scrolltop {
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);

  &__icon {
    display: block;
  }

  &:hover {
    .c-scrolltop__icon {
      color: #fff;
    }
  }

  &__link {
    display: block;
    width: 54px;
    height: 54px;
    font-size: 18px;
    line-height: 54px;
    background-color: #525252;
    transition: all 0.2s ease-in-out;
    color: #fff;
    font-size: 21px;
    border-radius: 100%;

    @include media(768) {
      text-decoration: none;
      border: 0 none;
      width: 60px;
      height: 60px;
      line-height: 60px;
    }

    &:hover,
    &:focus {
      color: #ffffff;
    }
  }
}
