.u-display--flex {
  display: flex !important;
  flex-wrap: wrap !important;
}

// center vertically and horizontally
.u-flex--center {
  justify-content: center !important;
  align-items: center !important;
}

.u-flex--justify-center {
  justify-content: center !important;
}

.u-flex--align-center {
  align-items: center !important;
}

.u-flex--grow {
  flex-grow: 1 !important;
}

.u-flex--shrink {
  flex-shrink: 1 !important;
}

.u-flex--row {
  flex-direction: row !important;
}

.u-flex--column {
  flex-direction: column !important;
}

.u-flex--space-around {
  justify-content: space-around !important;
}