.u-full-width {
  width: 100% !important;
}

.u-display--none {
  display: none !important;
}

.u-display--block {
  display: block !important;
}

.u-display--inline-block {
  display: inline-block !important;
}

.u-txt--center {
  text-align: center !important;
}

.u-txt--left {
  text-align: left !important;
}

.u-txt--right {
  text-align: right !important;
}

.no-scroll {
  overflow: hidden;
}

.u-primary-white {
  color: #fff;
}