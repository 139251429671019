.c-legal {
  position: fixed;
  top: 0;
  z-index: 1000;
  background: #ffffff;
  height: 100vh;
  width: 100vw;
  padding: 2rem;
  overflow-y: scroll;
  transition: 0.3s left linear;

  // @include media(768) {
  //   height: 50vh;
  //   width: 50vw;
  // }

  &--inactive {
    left: -100vw;
  }

  &--active {
    left: 0;
  }

  &__headline {
    color: #313131;
  }

  &__link {
    cursor: pointer;

    &:hover {
      color: #11abb0;
    }
  }

  &__close {
    color: #11abb0cc;
    position: absolute;
    top: 10px;
    right: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    @include media(768) {
      top: 15px;
      right: 15px;
    }

    &:before {
      content: "";
      width: 5px;
      height: 30px;
      position: absolute;
      background: #11abb0cc;
      right: 15px;
      transform: rotate(-45deg);
    }

    &:after {
      content: "";
      width: 5px;
      height: 30px;
      position: absolute;
      background: #11abb0cc;
      right: 15px;
      transform: rotate(45deg);
    }
  }
}
