$gutter: 16px;
$gutter-tablet: 18px;
$gutter-mobile: 20px;

$small-gap: 2rem;
$medium-gap: 4rem;
$big-gap: 9rem;

$breakpoints: (
  480: 480px,
  768: 768px,
  900: 900px,
  1024: 1024px,
  1200: 1200px
) !default;
