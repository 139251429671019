*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  background: #0f0f0f;
  color: #878d92;
}

a,
a:visited {
  cursor: pointer;
  color: #fff;
}

a:hover,
a:focus {
  color: #11abb0cc;
}

h2 {
  font: 22px/30px "opensans-bold", sans-serif;
  margin-bottom: 1.2rem;
}

li:last-child {
  margin-bottom: 0;
}

p,
li {
  font-size: 1.6rem;
}