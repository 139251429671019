.section {
  &__headline {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;

    &--alone {
      @include media(1024) {
        margin-bottom: 3rem;
      }
    }
  }

  &__subheadline {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 2rem;
    font-size: 1.6rem;
  }
}

.c-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 1020px;
  width: 96%;

  @include media(1024) {
    padding-left: 0;
    padding-right: 0;
  }

  &__item {
    flex: 1;
    padding: 0 20px;
  }

  @for $i from 1 through 12 {
    &__item-#{$i} {
      width: 100%;

      @include media(480) {
        padding: 0 2rem;
        width: calc((#{$i} / 12) * 100%);
      }
    }
  }

  &--center-x {
    justify-content: center;
  }
}

.full-row {
  width: 100%;
}

@keyframes upDown {
  0% {
    top: 0px;
  }

  25% {
    top: 5px;
  }

  50% {
    top: 0px;
  }

  75% {
    top: 5px;
  }

  100% {
    top: 0px;
  }
}

@keyframes downUp {
  0% {
    bottom: 0px;
  }

  25% {
    bottom: 5px;
  }

  50% {
    bottom: 0px;
  }

  75% {
    bottom: 5px;
  }

  100% {
    bottom: 0px;
  }
}

.icon-down-circle {
  position: relative;
  transition: all 0.3s ease-in-out;
  top: 0px;

  &:hover {
    animation-duration: 1.3s;
    animation-name: upDown;
    animation-iteration-count: 1;
  }
}

.icon-up-circle {
  position: relative;
  transition: all 0.3s ease-in-out;
  bottom: 0px;

  &:hover {
    animation-duration: 1.3s;
    animation-name: downUp;
    animation-iteration-count: 1;
  }
}
