.home {
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 2rem;

  &__char {
    color: #ffffff;
  }
}

@keyframes glimmerElement {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.home__typing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: auto;
  line-height: 1.25;
  min-height: 110px;

  @include media(480) {
    min-height: none;
    justify-content: center;
  }

  @include media(768) {
    flex-direction: row;
  }
}

.home__headline {
  @include media(768) {
    margin-top: 0;
  }

  &:last-of-type:after {
    content: "";
    position: relative;
    top: 2px;
    display: inline-block;
    left: 7px;
    width: 0.5rem;
    height: 2.5rem;
    background-color: rgba(255, 255, 255, 0.85);
    animation: glimmerElement 1.2s;
    animation-iteration-count: infinite;
  }
}
