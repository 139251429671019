$text-dark: #313131;

.c-contact {
  background: #191919;
  padding-top: 5rem;
  padding-bottom: 5rem;
  color: #636363;

  @include media(768) {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  &__header {
    display: flex;
    margin-bottom: 1rem;

    @include media(768) {
      margin-bottom: 3rem;
    }

    @include media(1024) {
      margin-bottom: 4rem;
    }
  }

  &__headline {
    display: flex;
    align-items: center;
    font: 26px/32px "opensans-bold", sans-serif;
    text-align: center;
    margin-bottom: 3rem;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
    color: #ebeeee;

    @include media(768) {
      font: 22px/24px "opensans-bold", sans-serif;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-bottom: 0.6rem;
    }

    &:before {
      font-family: "FontAwesome";
      content: "\f0e0";
      padding-right: 20px;
      font-size: 45px;
      line-height: 45px;
      color: #ebeeee;
    }

    &-content {
      display: inline-block;
    }
  }

  &__lead {
    display: flex;
    text-align: center;
    color: #ffffff;
    font: 17px/33px "opensans-light", sans-serif;
    margin: 0 auto;

    @include media(768) {
      font: 18px/36px "opensans-light", sans-serif;
      text-align: left;
    }
  }

  &__form {
    display: flex;
    width: 100%;
    padding: 0 1rem;

    @include media(768) {
      padding: 0;
    }
  }
}

.c-form {
  display: flex;
  margin-bottom: 3rem;
  width: 100%;

  &__fieldset {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @include media(768) {
      flex-wrap: wrap;
      flex-direction: row;
    }

    &-item {
      flex: 0 1 100%;
      width: 100%;
      padding: 0;

      @include media(768) {
        padding: 1.5rem 3rem;
        flex: 0 1 50%;
        width: 50%;
        padding: 1.5rem;
      }

      @include media(1024) {
        padding: 3rem 4.5rem;
      }
    }

    &-cta {
      text-align: center;
    }
  }

  &__button {
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    background: transparent;
    border: 2px solid #ffffff;
    cursor: pointer;
    height: auto;
    display: inline-block;
    border-radius: 3px;
    width: 175px;
    margin-top: 3rem;

    @include media(768) {
      margin-top: 0;
    }

    &:focus {
      box-shadow: inset 0 0 5px #fff;
    }

    &:hover {
      color: #0d0d0d;
      background: #fff;
    }
  }

  &__label {
    font: 15px/24px "opensans-bold", sans-serif;
    margin: 12px 0;
    color: #ebeeee;
    display: inline-block;
    width: 100%;
  }

  &__required {
    color: #11abb0cc;
    font-size: 13px;
  }

  &__input,
  &__input[type="text"],
  &__textarea,
  &__select {
    padding: 12px 14px;
    color: $text-dark;
    background: #f3f3f3;
    border: 0;
    outline: none;
    font-size: 15px;
    line-height: 24px;
    border-radius: 5px;
    margin-bottom: 0.6rem;
    width: 100%;

    @include media(768) {
      padding: 1.8rem 2rem;
      margin-bottom: 4rem;
    }

    &:focus {
      color: $text-dark;
      background-color: #fff;
    }
  }

  &__error,
  &__success {
    width: 100%;
    margin-left: 0;
    justify-content: center;
    background: #0f0f0f;
    padding: 2.5rem;
    font-size: 1.6rem;
    margin-bottom: 3.5rem;

    @include media(768) {
      width: 65%;
      margin: 0 auto;
    }

    &--visible {
      display: flex;
    }

    i {
      margin-right: 1rem;
    }
  }

  &__error {
    display: none;
    color: #d72828;
  }

  &__success {
    color: #11abb0cc;
  }

  #image-loader {
    display: none;
    position: relative;
    left: 18px;
    top: 17px;
  }
}
